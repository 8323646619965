import APIConstant from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { getRequest, postRequest } from "utils/apiUtils";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class RegisterStore {

    memoList = []
    memoRegisterData = []
    toolboxEducationRegisterData = []
    competencyList = []
    competencyListRegisterData = [] //

    constructor() {
        makeAutoObservable(this);
    }



    getMemoList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: APIConstant.GET_ALL_MEMO });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setMemoList(result);
                    resolve(result);
                }
                resolve([]);
            } catch (error) {
                reject(error);
            }
        })
    }


    getMemoRegisterData = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({ url: APIConstant.GET_MEMO_REGISTER_LIST, payload: body });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setMemoRegisterData(result);
                    return resolve(result);
                }
                this.setMemoRegisterData();
                return resolve([]);
            } catch (error) {
                this.setMemoRegisterData();
                return reject(error);
            }
        })
    }

    getToolboxEducationRegisterData = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({ url: APIConstant.GET_TOOLBOX_EDUCATION_REGISTER, payload: body });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setToolboxEducationRegisterData(result);
                    return resolve(result);
                }
                this.setToolboxEducationRegisterData();
                return resolve([]);
            } catch (error) {
                this.setToolboxEducationRegisterData();
                return reject(error);
            }
        })
    }

    getAllCompetencyList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: APIConstant.GET_ALL_COMPETENCY });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setCompetencyList(result);
                    resolve(result);
                }
                resolve([]);
            } catch (error) {
                reject(error);
            }
        })
    }

    getCompetencyData = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({ url: APIConstant.GET_COMPETENCY_DATA, payload: body });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setCompetencyListRegisterData(result);
                    return resolve(result);
                }
                this.setCompetencyListRegisterData();
                return resolve([]);
            } catch (error) {
                this.setCompetencyListRegisterData();
                return reject(error);
            }
        })
    }

    setMemoList = (data) => {
        runInAction(() => {
            this.memoList = data
        })
    }

    setMemoRegisterData = (data = []) => {
        runInAction(() => {
            this.memoRegisterData = data
        })
    }

    setToolboxEducationRegisterData = (data = []) => {
        runInAction(() => {
            this.toolboxEducationRegisterData = data
        })
    }

    setCompetencyList = (data = []) => {
        runInAction(() => {
            this.competencyList = data
        })
    }

    setCompetencyListRegisterData = (data = []) => {
        runInAction(() => {
            this.competencyListRegisterData = data
        })
    }

}

export default RegisterStore