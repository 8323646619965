import { Spin } from 'antd';
import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useMainStore } from 'store/mobXStores/mainStore';

const AutoLogin = () => {
	const mainStore = useMainStore();

	const { autoLoginStore } = mainStore;

	useEffect(() => {
		const { autoLogin } = autoLoginStore;
		autoLogin(window.location.search);
	}, []);

	return (
		<Observer>
			{() => {
				const { autoLoginSuccess, autoLoginInProgress } = autoLoginStore;

				if (!autoLoginInProgress && autoLoginSuccess) {
					return <Navigate to="/" />;
				}

				return (
					<div className="bg-gray-lighter">
						<div className="container">
							<div className="d-flex justify-content-center align-items-center vh-100">
								<Spin size="large" />
							</div>
						</div>
					</div>
				);
			}}
		</Observer>
	);
};

export default AutoLogin;
