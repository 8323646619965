import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { mainStore, StoreProvider } from 'store/mobXStores/mainStore';
import AppRoutes from 'routes/AppRoutes';

const themes = {
	dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
	return (
		<div className="App">
			<Provider store={store}>
				<StoreProvider value={mainStore}>
					<BrowserRouter history={history}>
						<ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
							<AppRoutes />
						</ThemeSwitcherProvider>
					</BrowserRouter>
				</StoreProvider>
			</Provider>
		</div>
	);
}

export default App;
