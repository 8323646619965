import APIConstant, { GET_ALL_FACILITY_LIST_BY_ORG_ID, GET_ALL_ORGANIZATION_LIST, STAFF_DETAILS_BY_FACILITY_ID } from 'constants/ApiConstant';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray, sendNotification } from 'utils/utilities';

class StaffListStore {
	isLoading = false;

	orgListData = [{}];
	facilityListByOrgId = [];

	staffData = [];

	constructor({ reportsStore }) {
		makeAutoObservable(this);
		this.reportsStore = reportsStore;
	}

	getAllOrganizations = async () => {
		try {
			this.updateGlobalLoadingState();
			const { response } = await getRequest({
				url: APIConstant.GET_ALL_ORGANIZATION_LIST,
				rbu: MYM_WEB,
			});

			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.orgListData = result;
				});
			}
			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			this.updateGlobalLoadingState();
			this.orgListData = [{}];
		}
	};

	handleOrganizationChange = async e => {
		try {
			this.updateGlobalLoadingState();

			const { response } = await getRequest({
				url: `${APIConstant.GET_ALL_FACILITY_LIST_BY_ORG_ID}/${e}`,
				rbu: MYM_WEB,
			});

			if (response.success && Array.isArray(response.result)) {
				runInAction(() => {
					this.facilityListByOrgId = [...response.result];
				});
			}

			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		}
	};

	handleFacilityChange = async e => {
		try {
			this.updateGlobalLoadingState();

			const { response } = await getRequest({
				url: `${APIConstant.STAFF_DETAILS_BY_FACILITY_ID}${e}`,
				rbu: MYM_WEB,
			});

			runInAction(() => {
				this.updateGlobalLoadingState();
			});

			if (response.success && Array.isArray(response.result)) {
				runInAction(() => {
					this.staffData = response.result.map((staff, i) => {
						return {
							...staff,
							key: i,
						};
					});
				});
			}
		} catch (error) {
			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		}
	};

	updateGlobalLoadingState = () => {
		this.reportsStore.updateReportsLoadingState();
	};
}

export default StaffListStore;
