import { Row, Spin } from 'antd';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMainStore } from 'store/mobXStores/mainStore';

const Logout = () => {
	const navigate = useNavigate();
	const mainStore = useMainStore();

	useEffect(() => {
		const { loginStore } = mainStore;

		const { resetLoginStore } = loginStore;

		resetLoginStore();

		sessionStorage.clear();

		navigate(`${AUTH_PREFIX_PATH}/login`);
	}, []);

	return (
		<Fragment>
			<div className="login_container">
				<div className="container d-flex flex-column justify-content-center h-100">
					<Row justify="center">
						<Spin />
					</Row>
				</div>
			</div>
		</Fragment>
	);
};

export default Logout;
