import APIConstant from 'constants/ApiConstant';
import { LOGGED_IN_USER_ID, LOGGED_IN_USER_NAME, USER_AUTHENTICATION_TOKEN } from 'constants/AuthConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { postRequest } from 'utils/apiUtils';
import { isValidArray } from 'utils/utilities';

class LoginStore {
	isLoading = false;
	isLogInSuccess = false;

	constructor() {
		makeAutoObservable(this);
	}

	handleUserLogin = async data => {
		try {
			this.isLoading = true;
			const { response } = await postRequest({
				url: APIConstant.USER_LOGIN,
				payload: data,
			});
			const { result, success } = response;

			if (success && isValidArray(result)) {
				const [{ token_id, user_id, first_name, last_name }] = result;
				sessionStorage.setItem(USER_AUTHENTICATION_TOKEN, token_id);
				sessionStorage.setItem(LOGGED_IN_USER_ID, user_id);
				sessionStorage.setItem(LOGGED_IN_USER_NAME, first_name + ' ' + last_name);

				runInAction(() => {
					this.isLogInSuccess = true;
					this.isLoading = false;
				});
			}
		} catch (error) {
			runInAction(() => {
				this.isLogInSuccess = false;
				this.isLoading = false;
			});
		}
	};

	resetLoginStore = () => {
		runInAction(() => {
			this.isLoading = false;
			this.isLogInSuccess = false;
		});
	};
}

export default LoginStore;
