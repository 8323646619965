class Notify {
	constructor() {
		// Notifications types
		this.success = 'success';
		this.error = 'error';
		this.info = 'info';
		this.warning = 'warning';

		// Notification Messages
		this.something_went_wrong = 'Something went wrong';
		this.send_email_success = 'Email sent successfully';
		this.send_email_fail = 'Failed to send email';
	}
}

const notify = new Notify();

export default notify;
