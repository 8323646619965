import axios from 'axios';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import APIConstant from 'constants/ApiConstant';
import { USER_AUTHENTICATION_TOKEN } from 'constants/AuthConstant';

const apiEndpoint = process.env.REACT_APP_EDU_API_URL;

const client = axios.create({
	baseURL: apiEndpoint,
	headers: {
		'content-type': 'application/json',
		accept: 'application/json',
	},
});

// request handling interceptor
client.interceptors.request.use(
	config => {
		if (config.url === APIConstant.USER_LOGIN) {
			config.headers['token-id'] = 0;
		} else if (config.url === APIConstant.USER_AUTO_LOGIN) {
			config.headers['token-id'] = config.headers['token-id'];
		} else {
			config.headers['token-id'] = sessionStorage.getItem(USER_AUTHENTICATION_TOKEN);
		}

		config.headers['device-type'] = process.env.REACT_APP_DEVICE_TYPE;

		return config;
	},
	error => {
		console.log('error :>> ', error);
		return Promise.reject(error);
	},
);

// error handling interceptor
client.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error.response.status >= 400 && error.response.config.url !== APIConstant.USER_LOGIN) {
			sessionStorage.clear();
			window.location.href = `${AUTH_PREFIX_PATH}/login`;
		}
	},
);

// rbu = Request baseURL
export const getRequest = async ({ url, extraParameters = {} }) => {
	try {
		const res = await client.get(url, { ...extraParameters });
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const postRequest = async ({ url, payload = {}, extraParameters = {} }) => {
	try {
		const res = await client.post(url, payload, { ...extraParameters });
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const putRequest = async ({ url, payload = {}, extraParameters = {} }) => {
	try {
		const res = await client.put(url, payload, { ...extraParameters });
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const patchRequest = async ({ url, payload = {}, extraParameters = {} }) => {
	try {
		const res = await client.patch(url, payload, { ...extraParameters });
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};

export const deleteRequest = async ({ url, extraParameters = {} }) => {
	try {
		const res = await client.delete(url, { ...extraParameters });
		const { data, status } = res;
		if (status === 204) {
			return {
				response: {
					success: false,
					message: 'Something Went Wrong!',
					data: [],
				},
				error: true,
			};
		}
		return { response: data, error: false };
	} catch (error) {
		return { response: { isLogout: true }, error: true };
	}
};
