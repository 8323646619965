import APIConstant from 'constants/ApiConstant';
import { LOGGED_IN_USER_ID } from 'constants/AuthConstant';
import { DATE_FORMAT_MM, DATE_FORMAT_YYYY } from 'constants/DateConstant';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { isNumber, isString } from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray, sendNotification, sortData } from 'utils/utilities';

class AirPurifierStore {

	organizationList = [
		{
			org_name: "BlueCross",
			org_id: 13
		}
	]

	isUploadingFile = false;
	isSubmittingData = false;

	boxHillFacilityData = [];
	areasByFid = [];
	uploadAirPurifierModalStatus = false;
	airPurifierData = [];
	isLoadingData = false;

	constructor({ reportsStore }) {
		makeAutoObservable(this);
		this.reportsStore = reportsStore;
	}

	getFacilityListByOrgId = (orgId) => {
		return new Promise(async (resolve, reject) => {
			try {
				this.updateGlobalLoadingState();

				const { response } = await getRequest({
					url: `${APIConstant.GET_ALL_FACILITY_LIST_BY_ORG_ID}/${orgId}`,
					rbu: MYM_WEB,
				});

				const { result, error } = getValidDataFromResponse({ response, isRequired: false });

				if (!error && isValidArray(result)) {
					runInAction(() => {
						this.boxHillFacilityData = result;
					});

					runInAction(() => {
						this.updateGlobalLoadingState();
					});

					return resolve({ result });
				}

				runInAction(() => {
					this.updateGlobalLoadingState();
				});

				return reject({ result: [] });
			} catch (error) {
				runInAction(() => {
					this.updateGlobalLoadingState();
				});
			}
		});
	};

	getAllAirPurifierData = async data => {
		try {
			this.updateGlobalLoadingState();

			const { response } = await postRequest({
				url: `${APIConstant.GET_ALL_AIR_PURIFIER_SCHEDULAR_DATA}`,
				rbu: MYM_WEB,
				payload: data,
			});

			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.airPurifierData = sortData(result, 'area');
				});
			}

			if (!isValidArray(result)) {
				runInAction(() => {
					this.airPurifierData = [];
				});
			}

			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		}
	};

	filterData = formRef => {
		let { facility_ids = '', date = '' } = formRef.getFieldValue();

		let body = {
			facility_ids,
			date: moment(date).isValid() ? moment(date).format('MM-YYYY') : '',
		};

		if (isString(body.date) && isNumber(body.facility_ids) && body.date !== "") {
			return this.getAllAirPurifierData(body);
		}

		if (facility_ids === '') {
			return sendNotification({ type: 'error', message: 'Please select a facility' });
		}

		// return this.getAllAirPurifierData(body);
	};

	handleFacilityChange = async fid => {
		try {
			this.updateGlobalLoadingState();
			const { response } = await getRequest({ url: `${APIConstant.GET_AREA_BY_FACILITY_ID}/${fid}`, rbu: MYM_WEB });

			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.areasByFid = sortData(result, 'area_name');
				});
			}
			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			console.log('error :>> ', error);
			this.areasByFid = [];
			this.updateGlobalLoadingState();
		}
	};

	submitAirPurifierData = async data => {
		try {
			this.isSubmittingData = true;
			let body = {
				...data,
				month: moment(data.uploadDate).format(DATE_FORMAT_MM),
				year: moment(data.uploadDate).format(DATE_FORMAT_YYYY),
				user_id: sessionStorage.getItem(LOGGED_IN_USER_ID),
				from_web: 1,
			};

			delete body['uploadDate'];

			const { response } = await postRequest({ url: APIConstant.UPLOAD_AIR_PURIFIER, rbu: MYM_WEB, payload: body });

			const { error } = getValidDataFromResponse({ response });

			if (error) {
				this.isSubmittingData = false;
				return;
			}

			runInAction(() => {
				this.changeAirPurifierModalStatus();
				this.isSubmittingData = false;
			});
		} catch (error) {
			runInAction(() => {
				this.isSubmittingData = false;
			});
		}
	};

	updateGlobalLoadingState = () => {
		this.reportsStore.updateReportsLoadingState();
	};

	changeAirPurifierModalStatus = () => {
		runInAction(() => {
			this.uploadAirPurifierModalStatus = !this.uploadAirPurifierModalStatus;
		});
	};

	changeFileUploadLoadingState = val => {
		runInAction(() => {
			this.isUploadingFile = val;
		});
	};
}

export default AirPurifierStore;
