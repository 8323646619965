import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import React from 'react';

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() => import('views/login')),
	},
];

export const protectedRoutes = [
	{
		key: 'dashboard',
		path: `${APP_PREFIX_PATH}/dashboard`,
		Component: React.lazy(() => import('views/dashboards')),
	},
	{
		key: 'reports',
		path: `${APP_PREFIX_PATH}/reports`,
		Component: React.lazy(() => import('views/reports')),
	},
	{
		key: 'kpi',
		path: `${APP_PREFIX_PATH}/kpi`,
		Component: React.lazy(() => import('views/kpi')),
	},
	{
		key: 'survey',
		path: `${APP_PREFIX_PATH}/survey`,
		Component: React.lazy(() => import('views/survey')),
	},
	{
		key: 'registers',
		path: `${APP_PREFIX_PATH}/registers`,
		Component: React.lazy(() => import('views/registers')),
	},
	{
		key: 'education',
		path: `${APP_PREFIX_PATH}/education`,
		Component: React.lazy(() => import('views/education')),
	},
];
