import APIConstant from 'constants/ApiConstant';
import notify from 'constants/notificationConstants';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray, sendNotification } from 'utils/utilities';

class GlobalStore {
	rowId = '';
	allOrganizations = [];
	facilitiesByOrg = [];
	isFetchingFacility = false;
	selectedOrganization = '';
	allStateList = [];

	constructor() {
		makeAutoObservable(this);
	}

	getPreSignedUrl = async (url, rowId) => {
		try {
			this.rowId = rowId;
			const { response } = await postRequest({ url: APIConstant.AWS_PRESIGNED_URL, rbu: MYM_WEB, payload: { url } });
			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && result) {
				window.open(result, '_blank');
			}

			this.rowId = '';
		} catch (error) {
			this.rowId = '';
		}
	};

	fileUploadUtility = fileData => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.UPLOAD_CERTIFICATE, rbu: MYM_WEB, payload: fileData });

				const { result, error } = getValidDataFromResponse({ response });

				if (!error && result) {
					return resolve({ url: result, error: false });
				}

				return reject({ url: '', error: true });
			} catch (error) {
				console.log('error :>> ', error);
				return reject({ url: '', error: true });
			}
		});
	};

	getAllOrganizations = async () => {
		try {
			const { response } = await getRequest({ url: APIConstant.GET_ALL_ORGANIZATION_LIST });

			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.setAllOrganizations(result);
					this.selectedOrganization = result[0].org_id;
				});
			}
		} catch (error) {
			console.log('error :>> ', error);
			this.setAllOrganizations();
		}
	};

	getAllStateList = async () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({
					url: APIConstant.GET_ALL_STATE_LIST,
					rbu: MYM_WEB,
				});

				if (response.success && Array.isArray(response.result)) {
					runInAction(() => {
						this.allStateList = [...response.result];
					});
					resolve();
				}
				resolve()
			} catch (error) {
				console.log('Error');
				reject(error);
			}
		})
	};

	getFacilityListByOrgId = orgId => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsFetchingFacility(true);
				const { response } = await getRequest({ url: `${APIConstant.GET_ALL_FACILITY_LIST_BY_ORG_ID}/${orgId}` });

				const { result, error } = getValidDataFromResponse({ response, isRequired: false });

				if (!error && isValidArray(result)) {
					this.setFacilitiesByOrg(result);
					this.setIsFetchingFacility();
					resolve(result);
				} else {
					throw new Error('Failed to fetch facility');
				}
			} catch (error) {
				this.setIsFetchingFacility();
				sendNotification({ type: notify.error, message: notify.something_went_wrong });
				reject();
			}
		});
	};

	sendEmail = emailBody => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.SEND_EMAIL, payload: emailBody });

				const { success } = response;

				if (success) {
					sendNotification({ type: notify.success, message: notify.send_email_success });
					resolve();
				} else {
					throw new Error('Failed to send email');
				}
			} catch (error) {
				reject();
			}
		});
	};

	uploadMonthlyEducationFile = body => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await postRequest({ url: APIConstant.UPLOAD_IMAGE, payload: body });
				const { success, result } = response;

				if (success) {
					resolve({ fileUrl: result });
				} else {
					throw new Error('Failed to upload');
				}
			} catch (error) {
				reject({ fileUrl: '' });
			}
		});
	};

	setSelectedOrganization = (value = '') => {
		runInAction(() => {
			this.selectedOrganization = value;
		});
	};

	setFacilitiesByOrg = (data = []) => {
		runInAction(() => {
			this.facilitiesByOrg = data;
		});
	};

	setIsFetchingFacility = (value = false) => {
		runInAction(() => {
			this.isFetchingFacility = value;
		});
	};

	setAllOrganizations = (data = []) => {
		runInAction(() => {
			this.allOrganizations = data;
		});
	};
}

export default GlobalStore;
