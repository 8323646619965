import MainLayout from 'components/layout-components/MainLayout';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import { protectedRoutes } from 'configs/RoutesConfig';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AutoLogin from 'views/autologin';
import Login from 'views/login';
import Logout from 'views/logout';
import PageNoteFound from 'views/notFound/PageNoteFound';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

const AppRoutes = () => {
	return (
		<Routes>
			{/* Public Routes */}
			<Route
				path={`${AUTH_PREFIX_PATH}/login`}
				element={
					<PublicRoute>
						{' '}
						<Login />{' '}
					</PublicRoute>
				}
			/>
			<Route path="/autologin" element={<AutoLogin />} />
			<Route path="/not-found" element={<PageNoteFound />} />
			<Route path="/logout" element={<Logout />} />

			{/* Private Routes */}

			<Route
				path="/"
				element={
					<ProtectedRoute>
						{' '}
						<MainLayout />{' '}
					</ProtectedRoute>
				}
			>
				{protectedRoutes.map(({ key, path, Component }, index) => {
					return <Route key={key + index} path={path} element={<Component />} />;
				})}
			</Route>
			<Route path="*" element={<Navigate to="/not-found" />} />
		</Routes>
	);
};

export default AppRoutes;
