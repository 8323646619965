import APIConstant from 'constants/ApiConstant';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment';
import { getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse } from 'utils/utilities';

class MonthlyEducationStore {
	isSubmittingData = false;

	allStateList = [];

	constructor() {
		makeAutoObservable(this);
	}

	getAllStateList = async () => {
		try {
			const { response } = await getRequest({
				url: APIConstant.GET_ALL_STATE_LIST,
				rbu: MYM_WEB,
			});

			if (response.success && Array.isArray(response.result)) {
				runInAction(() => {
					this.allStateList = [...response.result];
				});
			}
		} catch (error) {
			console.log('Error');
		}
	};

	submitMonthlyEducationData = data => {
		return new Promise(async (resolve, reject) => {
			try {
				const { education_date, ...rest } = data;

				const new_body = {
					education_date: moment(education_date).format(`MMMM YYYY`),
					...rest,
				};

				this.isSubmittingData = true;

				const { response } = await postRequest({
					url: APIConstant.MONTHLY_EDUCATION_DETAILS,
					rbu: MYM_WEB,
					payload: new_body,
				});

				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					runInAction(() => {
						this.isSubmittingData = false;
					});
					resolve();
				} else {
					throw new Error(`Failed to submit`);
				}
			} catch (error) {
				runInAction(() => {
					this.isSubmittingData = false;
				});
			}
		});
	};
}

export default MonthlyEducationStore;
