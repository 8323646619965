import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { Observer } from 'mobx-react';
import { useMainStore } from 'store/mobXStores/mainStore';
import { Navigate } from 'react-router-dom';
import LoginForm from './LoginForm';

const backgroundStyle = {
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundColor: '#00477e',
};

const Login = props => {
	const theme = useSelector(state => state.theme.currentTheme);
	const mainStore = useMainStore();
	const { loginStore } = mainStore;
	return (
		<Observer>
			{() => {
				const { isLoading, isLogInSuccess } = loginStore;
				if (!isLoading && isLogInSuccess) {
					return <Navigate to="/" replace={true} />;
				}
				return (
					<div className="auth-container">
						<div className={`h-100 ${theme === 'light' ? 'bg-white' : ''}`}>
							<Row justify="center" className="align-items-stretch h-100">
								<Col xs={0} sm={0} md={0} lg={12}>
									<div className="d-flex flex-column justify-content-between h-100 px-4" style={backgroundStyle}>
										<div className="text-right">
											<img src="/img/logo1.png" alt="logo" style={{ width: '170px', padding: '20px' }} />
										</div>
										<Row justify="center">
											<Col className="text-center" xs={0} sm={0} md={0} lg={18}>
												<img className="img-fluid mb-5 w-50" src="/img/Asepsis.png" alt="" />
												<h1 className="text-white text-center">Welcome to Education Web</h1>
												<p className="text-white text-center">Australia's specialist Cleaning and Laundry company for Aged Care Facilities</p>
											</Col>
										</Row>
										<div className="d-flex justify-content-end pb-4">
											<div>
												<a className="text-white" href="/#" onClick={e => e.preventDefault()}>
													Term & Conditions
												</a>
												<span className="mx-2 text-white"> | </span>
												<a className="text-white" href="/#" onClick={e => e.preventDefault()}>
													Privacy & Policy
												</a>
											</div>
										</div>
									</div>
								</Col>
								<Col xs={20} sm={20} md={24} lg={12}>
									<div className="d-flex flex-column justify-content-center h-100">
										<Row justify="center">
											<Col xs={24} sm={24} md={20} lg={12} xl={10}>
												<h1 style={{ color: '#00477e' }}>Sign In</h1>
												<div className="mt-4">
													<LoginForm {...props} />
												</div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				);
			}}
		</Observer>
	);
};

export default Login;
