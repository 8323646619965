import APIConstant from 'constants/ApiConstant';
import { EDUCATION_WEB, MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray } from 'utils/utilities';

class EducationRegisterStore {

    educationList = []
    educationData = []

    constructor() {
        makeAutoObservable(this);
    }

    getEducationList = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await getRequest({ url: `${APIConstant.GET_EDUCATION_LIST}/2`, rbu: MYM_WEB });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setEducationList(result);
                    resolve(result);
                }
                resolve([]);
            } catch (error) {
                reject(error);
            }
        })
    }

    getEducationData = async (body) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { response } = await postRequest({ url: APIConstant.GET_MONTHLY_EDUCATION_REGISTER, rbu: EDUCATION_WEB, payload: body });
                const { result, error } = getValidDataFromResponse({ response, isRequired: false });
                if (!error && isValidArray(result)) {
                    this.setEducationData(result);
                    resolve(result);
                }
                resolve([]);
            } catch (error) {
                reject(error);
            }
        })
    }



    setEducationList = (data = []) => {
        runInAction(() => {
            this.educationList = data;
        })
    }

    setEducationData = (data = []) => {
        runInAction(() => {
            this.educationData = data;
        })
    }


}

export default EducationRegisterStore;