// export const API_BASE_URL = 'https://your-api-url.com/';

// // Project API endpoints

// // Global API endpoints
// export const GET_ALL_ORGANIZATION_LIST = `get_all_org_list_from_mym`;
// export const SEND_EMAIL = `report/send_email`;

// // AWS Presigned URL
// export const AWS_PRESIGNED_URL = 'get_presigned_url';

// // Upload Certificate
// export const UPLOAD_CERTIFICATE = 'upload_certificate';

// // Authentication
// export const USER_LOGIN = 'login';
// export const USER_AUTO_LOGIN = 'toolbox_auto_login';

// // Reports -> Staff List Tab
// // export const GET_ALL_ORGANIZATION_LIST = 'get_all_org_list_from_mym';
// export const GET_ALL_FACILITY_LIST_BY_ORG_ID = 'get_all_fac_list_by_org_id_mym';
// export const STAFF_DETAILS_BY_FACILITY_ID = 'staff_details?facility_id=';

// // Reports -> Education Register Tab
// export const GET_EDUCATION_LIST = 'get_sub_toolboox/2';
// export const GET_TOOLBOX_DETAILS = 'get_toolbox_details_for_web';

// // Reports -> Monthly Education Tab
// export const GET_ALL_STATE_LIST = 'get_all_statelist';
// export const MONTHLY_EDUCATION_DETAILS = 'insert_monthly_education';

// // Reports -> Equipment Register Tab
// export const ALL_EQUIPMENT_DETAILS = 'all_equipment_details_for_mym';

// // Reports -> Air Purifier Tab
// export const GET_ALL_AIR_PURIFIER_SCHEDULAR_DATA = 'get_air_purifier_schedule_details';
// export const GET_AREA_BY_FACILITY_ID = 'get_facility_area_list';
// export const UPLOAD_AIR_PURIFIER = 'upload_air_purifier';

// // KPI -> KPI Reports
// export const KPI_REPORT = 'kpi_report';
// export const CREATE_KPI_REPORT = 'add_kpi_report';
// export const KPI_FIELD_DETAILS_BY_ID = 'kpi_field_details_by_orgid';
// export const DELETE_KPI_REPORT = 'kpi_report';

class APIConstant {

    // Global API endpoints
    static GET_ALL_ORGANIZATION_LIST = `get_all_org_list_from_mym`;
    static SEND_EMAIL = `report/send_email`;
    static AWS_PRESIGNED_URL = 'get_presigned_url';
    static UPLOAD_CERTIFICATE = 'upload_certificate';
    static GET_ALL_FACILITY_LIST_BY_ORG_ID = 'get_all_fac_list_by_org_id_mym';
    static UPLOAD_IMAGE = 'upload_image';

    // Authentication
    static USER_LOGIN = 'login';
    static USER_AUTO_LOGIN = 'toolbox_auto_login';

    // Reports
    static GET_EDUCATION_LIST = 'get_sub_toolboox';
    static GET_TOOLBOX_DETAILS = 'get_toolbox_details_for_web';
    static GET_ALL_STATE_LIST = 'get_all_statelist';
    static MONTHLY_EDUCATION_DETAILS = 'insert_monthly_education';
    static ALL_EQUIPMENT_DETAILS = 'all_equipment_details_for_mym';
    static GET_ALL_AIR_PURIFIER_SCHEDULAR_DATA = 'get_air_purifier_schedule_details';
    static GET_AREA_BY_FACILITY_ID = 'get_facility_area_list';
    static UPLOAD_AIR_PURIFIER = 'upload_air_purifier';
    static TOOLBOX_TYPES_WITH_SUB_EDUCATION = 'get_toolbox_with_subeducation';
    static INSERT_TOOLBOX_EDUCATION = 'insert_toolbox_education';
    static INSERT_MEMO = 'insert_memo_details';
    static GET_ALL_MEMO = 'get_all_memo_listing';
    static DELETE_MEMO = 'delete_memo_by_id';


    // KPI
    static KPI_REPORT = 'kpi_report';
    static CREATE_KPI_REPORT = 'add_kpi_report';
    static KPI_FIELD_DETAILS_BY_ID = 'kpi_field_details_by_orgid';
    static DELETE_KPI_REPORT = 'kpi_report';

    // Survey
    static CLEANING_SURVEY_DETAILS = 'cleaning_survey_details'
    static LAUNDRY_SURVEY_DETAILS = 'laundry_survey_details'
    static UPLOAD_SURVEY_REPORT = 'survey_details'

    // Register
    static AIR_PURIFIER_REGISTER = 'air_purifier_register'
    static GET_MONTHLY_EDUCATION_REGISTER = 'get_monthly_education_register_list'
    static GET_MEMO_REGISTER_LIST = 'get_memo_register_list'
    static GET_TOOLBOX_EDUCATION_REGISTER = 'get_toolbox_education_register_list'
    static GET_ALL_COMPETENCY = 'get_all_competencies'
    static GET_COMPETENCY_DATA = 'get_competency_register_list'
}

export default APIConstant
