import { Card, Grid, Layout } from 'antd';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import navigationConfig from 'configs/NavigationConfig';
import React, { Suspense } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Utils from 'utils';
import Footer from './Footer';
import HeaderNav from './HeaderNav';
import TopNav from './TopNav';

const { Content } = Layout;
const { useBreakpoint } = Grid;

const MainLayout = () => {
	const location = useLocation();

	const currentRouteInfo = Utils.getRouteInfo(navigationConfig, location.pathname);
	const screens = Utils.getBreakPoint(useBreakpoint());
	const isMobile = screens.length === 0 ? false : !screens.includes('lg');

	const { status } = useThemeSwitcher();

	if (status === 'loading') {
		return <Loading cover="page" />;
	}

	if (location.pathname === '/') {
		return <Navigate to={`${APP_PREFIX_PATH}/dashboard`} />;
	}

	return (
		<Layout>
			<HeaderNav isMobile={isMobile} />
			<TopNav routeInfo={currentRouteInfo} />
			<Layout className="app-container">
				<Layout className="app-layout">
					<div className={`app-content layout-top-nav`}>
						<Content>
							<Suspense fallback={<Loading cover="content" />}>
								<Card>
									<Outlet />
								</Card>
							</Suspense>
						</Content>
					</div>
					<Footer />
				</Layout>
			</Layout>
		</Layout>
	);
};

export default MainLayout;
