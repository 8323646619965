import { notification } from 'antd';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import APIConstant from 'constants/ApiConstant';
import { postRequest } from './apiUtils';

export const isValidArray = arr => {
	return arr && Array.isArray(arr) && arr.length > 0;
};

export const isValidObject = (obj, keys) => {
	if (isValidArray(keys)) {
		const newObjKeys = isValidObject(obj, []) ? Object.keys(obj) : [];

		if (!isValidArray(newObjKeys)) {
			return false;
		}

		let isValid = keys.length;
		keys.forEach(key => {
			if (newObjKeys.includes(key)) {
				isValid -= 1;
			}
		});
		return isValid === 0;
	}
	return obj && Object.keys(obj).length > 0;
};

export const getAllRequiredHeaders = () => {
	// rbu = Request baseURL
	let headers = {};

	headers['device-type'] = process.env.REACT_APP_DEVICE_TYPE;

	return headers;
};

export const getValidDataFromResponse = ({ response = [], isRequired = true, disableCatchErrorMessage = true, isWarning = true }) => {
	try {
		if (isValidObject(response, ['success', 'message', 'result'])) {
			const { message, result, success, isLogout = false } = response;

			if (isLogout) {
				throw new Error('Go For Logout.');
			}

			if (success && isRequired) {
				sendNotification({ message: message });
			} else if (!success && isWarning) {
				sendNotification({ message: message, type: 'error' });
			}
			return { result, error: !success };
		}
		throw new Error('Not a valid response');
	} catch (error) {
		console.log('error :>> ', error);
		const { isLogout = false } = response;
		if (isLogout) {
			window.location = `${AUTH_PREFIX_PATH}/login`;
		}
		// if (disableCatchErrorMessage) {
		// sendNotification({ message: "Something went wrong.", notificationType: "error" });
		// }
		return { result: [], error: true };
	}
};

export const sendNotification = ({ type = 'success', message, duration }) => {
	// types = [success, error, info, warning]
	const options = {
		placement: 'topRight',
		duration: duration || 3,
	};
	notification[type]({
		...options,
		message,
	});
};

export const sortData = (data, key) => {
	if (isValidArray(data)) {
		return data.sort((a, b) => a[key].toUpperCase().charCodeAt(0) - b[key].toUpperCase().charCodeAt(0));
	}
	return data;
};

export const convertFileToBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64String = reader.result;
			resolve({ base64String });
		};
		reader.onerror = error => reject(error);
	});
};

export const emailRegExp = new RegExp(`^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$`);

export const uploadMonthlyEducationFile = body => {
	return new Promise(async (resolve, reject) => {
		try {
			const { response } = await postRequest({ url: APIConstant.UPLOAD_IMAGE, payload: body });
			const { success, result } = response;

			if (success) {
				resolve({ fileUrl: result, success });
			} else {
				throw new Error('Failed to upload');
			}
		} catch (error) {
			reject({ fileUrl: '' });
		}
	});
};
