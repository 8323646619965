import APIConstant from 'constants/ApiConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray } from 'utils/utilities';

class AirPurifierRegisterStore {

    organizationList = [
        {
            org_name: "BlueCross",
            org_id: 13
        }
    ]

    isFetchingRegisterData = false

    airPurifierRegisterData = [];

    constructor() {
        makeAutoObservable(this);
    }

    getAirPurifierRegisterData = async (body) => {
        try {

            this.setIsFetchingRegisterData(true)

            const { response } = await postRequest({ url: APIConstant.AIR_PURIFIER_REGISTER, payload: body })

            const { result, error } = getValidDataFromResponse({ response, isRequired: false });

            if (!error && isValidArray(result)) {
                runInAction(() => {
                    this.setAirPurifierRegisterData(result);
                })
            } else {
                throw new Error('Something went wrong')
            }

            this.setIsFetchingRegisterData()


        } catch (error) {
            this.setAirPurifierRegisterData()
            this.setIsFetchingRegisterData()
        }
    }

    setAirPurifierRegisterData = (data = []) => {
        runInAction(() => {
            this.airPurifierRegisterData = data;
        })
    };

    setIsFetchingRegisterData = (value = false) => {
        runInAction(() => {
            this.isFetchingRegisterData = value
        })
    }

}

export default AirPurifierRegisterStore;