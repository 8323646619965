import { createContext, useContext } from 'react';
import AutoLoginStore from 'views/autologin/autoLogin.store';
import KPIInspection from 'views/kpi/kpi.store';
import LoginStore from 'views/login/login.store';
import AirPurifierStore from 'views/reports/airPurifier/airpurifier.store';
// import ToolBoxEducationStore from 'views/reports/educationModule/toolboxEducation/toolboxEducation.store';
import MemoStore from 'views/education/memo/memo.store';
import MonthlyEducationStore from 'views/education/monthlyEducation/monthlyEducation.store';
import ToolBoxEducationStore from 'views/education/toolboxEducation/toolboxEducation.store';
import AirPurifierRegisterStore from 'views/registers/airPurifier/airPurifierRegister.store';
import EducationRegisterStore from 'views/registers/education/educationRegister.store';
import EquipmentRegisterStore from 'views/registers/equipment/equipmentRegister.store';
import RegisterStore from 'views/registers/registers.store';
import EducationStore from 'views/reports/educationRegister/eduRegister.store';
import EquipmentStore from 'views/reports/equipment/equipment.store';
import ReportsStore from 'views/reports/reports.store';
import StaffListStore from 'views/reports/staffList/staffList.store';
import SurveyStore from 'views/survey/survey.store';
import GlobalStore from './global.store';

class MainStore {
	constructor() {
		this.loginStore = new LoginStore(this);
		this.reportsStore = new ReportsStore(this);
		this.staffListStore = new StaffListStore(this);
		this.monthlyEducationStore = new MonthlyEducationStore(this);
		this.toolBoxEducationStore = new ToolBoxEducationStore(this);
		this.equipmentStore = new EquipmentStore(this);
		this.globalStore = new GlobalStore(this);
		this.airPurifierStore = new AirPurifierStore(this);
		this.educationStore = new EducationStore(this);
		this.autoLoginStore = new AutoLoginStore(this);
		this.kpiStore = new KPIInspection(this);
		this.surveyStore = new SurveyStore(this);
		this.memoStore = new MemoStore(this);

		// Register Menu Stores
		this.airPurifierRegisterStore = new AirPurifierRegisterStore(this);
		this.educationRegisterStore = new EducationRegisterStore(this);
		this.equipmentRegisterStore = new EquipmentRegisterStore(this);
		this.registerStore = new RegisterStore(this)
	}
}

export const mainStore = new MainStore();

const storeContext = createContext({});

export const StoreProvider = storeContext.Provider;

export const useMainStore = () => useContext(storeContext);
