import React from 'react';
import { Navigate } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';
import { USER_AUTHENTICATION_TOKEN } from 'constants/AuthConstant';

const PublicRoute = ({ children }) => {
	const token = sessionStorage.getItem(USER_AUTHENTICATION_TOKEN);

	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : children;
};

export default PublicRoute;
