import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'MajesticERP-EducationWeb';
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect';
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboard/default`;
export const UNAUTHENTICATED_ENTRY = '/login';

export const THEME_CONFIG = {
	navType: 'TOP',
	sideNavTheme: 'SIDE_NAV_LIGHT',
	navCollapsed: true,
	topNavColor: '#C2CCD8',
	headerNavColor: '#00477E',
	locale: 'en',
	currentTheme: 'light',
	direction: 'ltr',
};
