import APIConstant, { ALL_EQUIPMENT_DETAILS } from 'constants/ApiConstant';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray, sortData } from 'utils/utilities';

class EquipmentStore {
	equipmentDetails = [];

	constructor({ reportsStore }) {
		makeAutoObservable(this);
		this.reportsStore = reportsStore;
	}

	getAllEquipmentDetails = async () => {
		try {
			this.updateGlobalLoadingState();

			const { response } = await getRequest({ url: APIConstant.ALL_EQUIPMENT_DETAILS, rbu: MYM_WEB });

			const { result, error } = getValidDataFromResponse({ response });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.equipmentDetails = sortData(result, 'facility_name');
				});
			}

			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			this.updateGlobalLoadingState();
		}
	};

	updateGlobalLoadingState = () => {
		this.reportsStore.updateReportsLoadingState();
	};
}

export default EquipmentStore;
