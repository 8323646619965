import { Button, Result } from 'antd';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig';
import React, { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageNoteFound = () => {
	const navigate = useNavigate();

	useEffect(() => {
		sessionStorage.clear();
	}, []);
	return (
		<Fragment>
			<Result
				status="404"
				title="404"
				subTitle="Sorry, the page you visited does not exist."
				extra={
					<Button onClick={() => navigate(`${AUTH_PREFIX_PATH}/login`)} type="primary">
						Back Home
					</Button>
				}
			/>
		</Fragment>
	);
};

export default PageNoteFound;
