import { makeAutoObservable, runInAction } from 'mobx';

class ReportsStore {
	isLoadingReportData = false;
	constructor() {
		makeAutoObservable(this);
	}

	updateReportsLoadingState = () => {
		runInAction(() => {
			this.isLoadingReportData = !this.isLoadingReportData;
		});
	};
}

export default ReportsStore;
