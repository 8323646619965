import { makeAutoObservable } from 'mobx';

class EquipmentRegisterStore {

    constructor() {
        makeAutoObservable(this);
    }


}

export default EquipmentRegisterStore;