import APIConstant from 'constants/ApiConstant';
import { getRequest, postRequest } from 'utils/apiUtils';
import { getValidDataFromResponse } from 'utils/utilities';

const { makeAutoObservable, runInAction, values } = require('mobx');

class ToolBoxEducationStore {
	setLoaderForToolBox = false;
	isSubmitting = false;

	toolBoxData = [];

	constructor() {
		makeAutoObservable(this);
	}

	getAllToolboxAndSubEducation = () => {
		return new Promise(async (resolve, reject) => {
			try {
				const { response } = await getRequest({ url: APIConstant.TOOLBOX_TYPES_WITH_SUB_EDUCATION });
				const { result, error } = getValidDataFromResponse({ response, isRequired: false });

				if (!error) {
					this.setToolBoxData(result);
					resolve();
				} else {
					throw new Error('Something went wrong');
				}

				// console.log('response', result, error);
			} catch (error) {}
		});
	};

	submitNewToolboxEducation = body => {
		return new Promise(async (resolve, reject) => {
			try {
				this.setIsSubmitting(true);
				const { response } = await postRequest({ url: APIConstant.INSERT_TOOLBOX_EDUCATION, payload: body });
				const { result, error } = getValidDataFromResponse({ response });

				if (!error) {
					this.setIsSubmitting();
					resolve();
				} else {
					throw new Error('Something went wrong');
				}
			} catch (error) {
				this.setIsSubmitting();
				reject();
			}
		});
	};

	setFetchingInitialToolboxData = (value = false) => {
		runInAction(() => {
			this.setLoaderForToolBox = value;
		});
	};

	setToolBoxData = (data = []) => {
		runInAction(() => {
			this.toolBoxData = data;
		});
	};

	setIsSubmitting = (value = false) => {
		runInAction(() => {
			this.isSubmitting = value;
		});
	};
}

export default ToolBoxEducationStore;
