import APIConstant from 'constants/ApiConstant';
import { MYM_WEB } from 'constants/RequestUrlConstant';
import { makeAutoObservable, runInAction } from 'mobx';
import { getRequest } from 'utils/apiUtils';
import { getValidDataFromResponse, isValidArray } from 'utils/utilities';

class EducationStore {
	educationList = [];
	educationData = [];

	constructor({ reportsStore }) {
		makeAutoObservable(this);
		this.reportsStore = reportsStore;
	}

	getEducationList = async () => {
		try {
			this.updateGlobalLoadingState();

			const { response } = await getRequest({ url: `${APIConstant.GET_EDUCATION_LIST}/2`, rbu: MYM_WEB });

			const { result, error } = getValidDataFromResponse({ response, isRequired: false });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.educationList = result;
				});
			}

			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			console.log('error :>> ', error);
			this.updateGlobalLoadingState();
		}
	};

	handleEducationChange = async (id, title) => {
		this.updateGlobalLoadingState();
		try {
			const { response } = await getRequest({ url: `${APIConstant.GET_TOOLBOX_DETAILS}?toolbox_id=${id}&title=${title}`, rbu: MYM_WEB });

			const { result, error } = getValidDataFromResponse({ response });

			if (!error && isValidArray(result)) {
				runInAction(() => {
					this.educationData = result;
				});
			}

			runInAction(() => {
				this.updateGlobalLoadingState();
			});
		} catch (error) {
			console.log('error :>> ', error);
			this.updateGlobalLoadingState();
		}
	};

	updateGlobalLoadingState = () => {
		this.reportsStore.updateReportsLoadingState();
	};
}

export default EducationStore;
