import React from 'react';
import { Button, Form, Input, Alert } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useMainStore } from 'store/mobXStores/mainStore';
import { Observer } from 'mobx-react';

export const LoginForm = props => {
	const mainStore = useMainStore();
	const { loginStore } = mainStore;

	const onLogin = values => {
		const { handleUserLogin } = loginStore;
		handleUserLogin(values);
	};

	return (
		<Observer>
			{() => {
				const { isLoading } = loginStore;
				return (
					<>
						<Form layout="vertical" name="login-form" onFinish={onLogin}>
							<Form.Item
								name="email"
								label="Email"
								rules={[
									{
										required: true,
										message: 'Please input your email',
									},
									{
										type: 'email',
										message: 'Please enter a validate email!',
									},
								]}
							>
								<Input prefix={<MailOutlined className="text-primary" />} />
							</Form.Item>
							<Form.Item
								name="password"
								label="Password"
								rules={[
									{
										required: true,
										message: 'Please input your password',
									},
								]}
							>
								<Input.Password prefix={<LockOutlined className="text-primary" />} />
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit" block loading={isLoading}>
									Sign In
								</Button>
							</Form.Item>
						</Form>
					</>
				);
			}}
		</Observer>
	);
};

export default LoginForm;
