import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AUTH_PREFIX_PATH, UNAUTHENTICATED_ENTRY, REDIRECT_URL_KEY } from 'configs/AppConfig';
import { LOGGED_IN_USER_ID, USER_AUTHENTICATION_TOKEN } from 'constants/AuthConstant';

const ProtectedRoute = ({ children }) => {
	const token = sessionStorage.getItem(USER_AUTHENTICATION_TOKEN);
	const userId = sessionStorage.getItem(LOGGED_IN_USER_ID);
	const location = useLocation();

	if (!token && !userId) {
		return <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
	}

	return children;
};

export default ProtectedRoute;
